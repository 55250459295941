input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill:enabled,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill:enabled,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
input:-webkit-autofill:enabled {
    -webkit-box-shadow: /*your box-shadow*/0 0 0 1000px #fff inset;
    /* -webkit-text-fill-color: #fff; */
    border-radius: unset;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}
input:-webkit-autofill::first-line {
    font-size: initial;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}